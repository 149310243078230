import { inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import {
    Header as DapHeader
} from '@dapclient/components/nav/Header';

export class Header extends DapHeader {
    renderNotificationButton = () => null;

    getPublicMenuItems = () => [
        {
            value: '/',
            label: 'Home',
            to: { pathname: '/', state: { logout: false } },
        },
        {
            value: '/about',
            label: 'About',
            to: { pathname: '/about' }
        },
        {
            value: '/project-search',
            label: 'Projects',
            to: { pathname: '/project-search' }
        },
        {
            value: '/dataset-search',
            label: 'Datasets',
            to: { pathname: '/dataset-search' }
        },
        {
            value: '/publications',
            label: 'Publications',
            to: { pathname: '/publications' }
        },
        {
            value: '/news',
            label: 'News',
            to: { pathname: '/news' }
        },
        {
            value: '/metrics',
            label: 'Metrics',
            to: { pathname: '/metrics' }
        },
        {
            value: '/faq',
            label: 'FAQ',
            to: { pathname: '/faq' }
        },
    ];
}

export default inject((store: any) => ({
    securityService: store.securityService,
    notificationService: store.notificationService,
}))(withRouter(Header));
