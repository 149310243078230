// the typing module uses typescript module augmentation to update the
// material theme type
import './themeTyping';

import { configureServices, bootstrap } from '@extensions/utils/bootstrap';
import AppService from '@extensions/services/AppService';

bootstrap(
  configureServices(
    new AppService(true)
  )
);
