import { saveAs } from 'file-saver';
import { makeObservable } from 'mobx';

import Dataset from '@extensions/models/Dataset';
import config from '@extensions/utils/ConfigUtil';
import DapApiAgent from '@extensions/utils/DapApiAgent';
import isEmptyOject from '@extensions/utils/isEmptyObj';
import isEmptyArray from '@extensions/utils/isEmptyArray';
import LambdaApiAgent from '@extensions/utils/LambdaApiAgent';
import buildScript from '@extensions/utils/buildDownloaderScript';
import { OperatingSystem } from '@extensions/models/FileOrderGroup';
import { DisplayType } from '@extensions/models/FileMetadataSchema';
import { DateRange } from '@extensions/components/core/date-picker';
import { AppliedFilter } from '@extensions/components/dataset/LargeDataOrder';

import { ICachingService } from '@extensions/services/ICachingService';
import { IHistoryService } from '@extensions/services/IHistoryService';
import { ISecurityService } from '@extensions/services/ISecurityService';
import { IContactUsService } from '@extensions/services/IContactUsService';
import { default as BaseCartService } from '@dapclient/services/CartService';
import { Status, INotificationService } from '@extensions/services/INotificationService';

export default class CartService extends BaseCartService {

    notificationId: string = 'downloader';
    notificationService: INotificationService;
    securityService: ISecurityService;
    contactUsService: IContactUsService;
    cachingService: ICachingService;
    historyService: IHistoryService;

    constructor(
        notificationService: INotificationService,
        securityService: ISecurityService,
        contactUsService: IContactUsService,
        cachingService: ICachingService,
        historyService: IHistoryService
    ) {
        super(notificationService, securityService, contactUsService, cachingService, historyService);
        this.notificationService = notificationService;
        this.securityService = securityService;
        this.contactUsService = contactUsService;
        this.cachingService = cachingService;
        this.historyService = historyService;
        makeObservable(this);
    }

    placeOrderNotInCart = (
        os: OperatingSystem,
        filters: Record<string, AppliedFilter>,
        currentDataSource: Dataset
    ): void => {
        if (this.securityService.userIsLoggedIn && this.notificationService) {
            this.notificationService.addNotification(
                this.notificationId,
                Status.Running,
                '',
                ''
            );
            const requestBody = {
                datasets: {},
            };
            requestBody.datasets[currentDataSource.name] = {
                query: {},
            };
            Object.values(filters).forEach((filter) => {
                const { value, attribute } = filter;
                if (
                    value == null ||
                    value === undefined ||
                    isEmptyArray(value) ||
                    isEmptyOject(value)
                ) {
                    return;
                }
                switch (attribute.downloadDisplay) {
                    case DisplayType.RANGE:
                        Object.assign(requestBody.datasets[currentDataSource.name].query, {
                            [attribute.dynamoFieldName]: { between: value },
                        });
                        break;
                    case DisplayType.LIST:
                        Object.assign(requestBody.datasets[currentDataSource.name].query, {
                            [attribute.dynamoFieldName]: value,
                        });
                        break;
                    case DisplayType.DATE:
                        const dateRange = value as DateRange;
                        if (dateRange.startDate !== null && dateRange.endDate !== null) {
                            Object.assign(
                                requestBody.datasets[currentDataSource.name].query,
                                {
                                    [attribute.dynamoFieldName]: {
                                        between: [
                                        dateRange.startDate.format('YYYYMMDD'),
                                        dateRange.endDate.format('YYYYMMDD'),
                                        ],
                                    },
                                }
                            );
                        }
                    break;
                    case DisplayType.NONE:
                    case DisplayType.NO_FILTER:
                    default:
                    console.log(`Bad filter type values! ${attribute.name}`);
                    break;
                }
            });
            const apiUrl = config.getConfig().lambdaApi;
            LambdaApiAgent.agent
            .put(`${apiUrl}/orders`)
            .send(requestBody)
            .then((resp) => {
                const { id } = resp.body;
                this.historyService.history.push(`/ds/${currentDataSource.name}`);
                this.notificationService.addNotification(
                    this.notificationId,
                Status.Success,
                '',
                ''
                );
                return id;
            })
            .then((orderId) => {
                this.notificationService.addNotification(
                this.notificationId,
                Status.Running,
                'Building script...',
                '',
                false
                );

                const stopSpinner = () =>
                this.notificationService.addNotification(
                    this.notificationId,
                    Status.Success,
                    '',
                    ''
                );

                let idQueryString = '';
                idQueryString = `&id[]=${orderId}`;
                return DapApiAgent.agent
                .get(`/api/file-downloader?os=${os}${idQueryString}`)
                .responseType('blob')
                .then((resp) => {
                    let fileName = `livewire-downloader-${orderId}`;
                    if (os === OperatingSystem.MAC || os === OperatingSystem.LINUX) {
                    const zip = buildScript(resp.body, fileName, 'livewire');
                    return zip.generateAsync({ type: 'blob' }).then((blob) => {
                        saveAs(blob, 'livewire-downloader.zip');
                        stopSpinner();
                    });
                    } else if (os === OperatingSystem.WINDOWS) {
                    fileName += '.exe';
                    saveAs(resp.body, fileName);
                    stopSpinner();
                    }
                });
            })
            .catch((error) => {
                this.notificationService.addNotification(
                this.notificationId,
                Status.Error,
                'Could Not Place Order',
                error
                );
            });
        }
    };
}
