import chroma from 'chroma-js';
import { createTheme } from '@mui/material/styles';
import '@fontsource/ibm-plex-mono';

const overriddenDefaultPalette = {
  primary: {
    main: '#262626', // dark dark grey, header bar
  },
  secondary: {
    main: '#53b03f', // green used in header fonts
    light: '#A7D79D',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  metrics: {
    main: '#53b03f',
  },
  timeline: {
    primary: 'rgb(108, 176, 70)',
    secondary: 'rgb(0, 169, 224)',
    chromaGradientScale: chroma.scale('Oranges'),
  },
  highlight: {
    main: 'rgb(255, 255, 0)',
  },
  background: {
    default: 'rgb(250, 250, 250)'
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.65)',
    disabled: 'rgba(255, 255, 255, 0.65)'
  }
}

const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiButton-root': {
            color: '#fff',
          }
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&&': {
            outline: 0,
          },
          '&&&:focus': {
            outline: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&&&': {
            outline: 0,
          },
          '&&&:focus': {
            outline: 0,
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#005b82',
          '&:hover': {
            color: '#005b82',
          },
          fontWeight: 400,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&&:before': {
            opacity: '0',
          },
          border: '1px solid rgba(0, 0, 0, .125)',
          borderBottom: 'none',
          '&.Mui-expanded': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          color: overriddenDefaultPalette.secondary.main,
          backgroundColor: '#f8f8f8',
          '& p': {
            margin: '0 !important',
          },
        },
        content: {
          marginBottom: 3,
          marginTop: 3,
          '&.Mui-expanded': {
            marginBottom: 3,
            marginTop: 3,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          borderTop: '1px solid rgba(0, 0, 0, .125)',
          padding: '23px 16px',
        },
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {},
        indicator: {
          backgroundColor: '#53b03f',
        },
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: overriddenDefaultPalette.secondary.main,
          fontWeight: 500,
          fontSize: '1rem',
        },
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, .125)'
        },
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: '#f8f8f8',
        },
      }
    },
    MuiTable: {
      styleOverrides: {
        root: {
          border: '1px solid rgba(0, 0, 0, .125)'
        },
      }
    },
  },
  spacing: factor => `${8 * factor}px`,
  palette: overriddenDefaultPalette,
  typography: {
    fontFamily: "'Open Sans',sans-serif",
    fontWeightMedium: 500,
    h1: {
      fontWeight: 'bold',
      fontSize: '1.7rem',
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '1.3rem',
    },
    h3: {
      fontSize: '0.875rem',
    },
    h4: {
      fontSize: '0.875rem',
    },
    h5: {
      fontSize: '0.875rem',
    },
    h6: {
      fontSize: '0.875rem',
    },
    body1: {
      fontSize: '0.875rem',
      lineHeight: '1.5715',
      fontVariant: 'tabular-nums',
    },
    body2: {
      fontSize: '1rem',
      lineHeight: '1.5715',
      fontVariant: 'tabular-nums',
    },
  },
  contentWidth: {
    max: '1000px',
    min: '300px',
  },
  FilterTitle: {
    root: {},
    titleText: {},
  },
  linkIcon: {
    marginBottom: -5,
  },
  FullPageForm: {
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    form: {
      marginBottom: 15,
      maxWidth: 450,
    },
    formFooter: {
      marginBottom: 15,
      textAlign: 'center',
      fontSize: '14px'
    },
  },
  Stat: {
    statValue: {
      fontSize: '2rem',
      lineHeight: 1,
      // color: theme.palette.secondary.main,
      fontFamily: "'Oswald',sans-serif",
    },
    statDescription: {
      fontSize: 10,
    },
  },
  monoFontFamily: "'IBM Plex Mono', monospace",
  openSansFontFamily: "'Open Sans', sans-serif !important",
  MuiAlert: {
    outlinedSuccess: {
      border: '1px solid #b7eb8f',
      backgroundColor: '#f6ffed',
      color: 'rgba(0,0,0,0.65)',
      fontSize: '14px',
    },
    outlinedInfo: {
      border: '1px solid #91d5ff',
      backgroundColor: '#e6f7ff',
      color: 'rgba(0,0,0,0.65)',
      fontSize: '14px',
    },
    outlinedWarning: {
      border: '1px solid #ffe58f',
      backgroundColor: '#fffbe6',
      color: 'rgba(0,0,0,0.65)',
      fontSize: '14px',
    },
    outlinedError: {
      border: '1px solid #ffccc7',
      backgroundColor: '#fff2f0',
      color: 'rgba(0,0,0,0.65)',
      fontSize: '14px',
    },
  },
  
});

theme.FilterTitle = {
  root: {
    backgroundColor: theme.palette.grey['200'],
  },
  titleText: {}
}
theme.Stat.statValue.color = theme.palette.secondary.main;
theme.typography.h1.color = theme.palette.secondary.main;
theme.typography.h2.color = theme.palette.secondary.main;
theme.typography.h3.color = theme.palette.secondary.main;
theme.typography.h4.color = theme.palette.secondary.main;
theme.typography.h5.color = theme.palette.secondary.main;
theme.typography.subtitle1.color = theme.palette.primary.main;

export default theme;
