import BaseDataset, {
    DatasetStats as BaseDatasetStats,
    DatasetRawStats as BaseDatasetRawStats,
    StatSegment as BaseStatSegment,
    Stats as BaseStats,
    IFileExtension as BaseIFileExtension,
    Downloadable as BaseDownloadable,
    Gap as BaseGap,
  } from '@dapclient/models/Dataset';
  import moment, { Moment } from 'moment';

  export default class Dataset extends BaseDataset {
    origCitation: string|null = null;

    constructor(response) {
      super(response);

      if (this.doi && !Boolean(this.citation)) {
        this.citation = this.getCitationWithAccessDate();
      }
    }

    getCitationWithAccessDate(dateAccessed?: Moment, dataDateRange?: [Moment, Moment]): string | undefined | null {
      if (this.origCitation) {
        let citation = this.origCitation;
        if (dateAccessed) {
          citation = citation.replace(
            'accessed <YYYY-MM-DD>',
            `accessed ${dateAccessed.format('YYYY-MM-DD')}`
          );
        }
        if (dataDateRange) {
          citation = citation.replace(
            '<YYYY-MM-DD> to <YYYY-MM-DD>',
            [
              dataDateRange[0].format('YYYY-MM-DD'),
              dataDateRange[1].format('YYYY-MM-DD'),
            ].join(' to ')
          );
        }
        return citation;
      }
      const accessedString = dateAccessed
        ? `${dateAccessed.format('DD MM YYYY')}`
        : '<DD Mon YYYY>';
      const doiString = this.doi ? ` DOI: ${this.doi}.` : '';
      const dateString = this.dynamoDataEnds
        ? ` ${moment(this.dynamoDataEnds).format('YYYY')}.`
        : '';
      return `
        Livewire Data Platform (LDP).${dateString} ${this.name}.
        Maintained by Livewire Data Platform for U.S. Department of Energy,
        Office of Energy Efficiency and Renewable Energy.${doiString}
        Accessed: ${accessedString}.
      `;
    }
  }

  export type IFileExtension = BaseIFileExtension;

  export type DatasetStats = BaseDatasetStats;

  export type DatasetRawStats = BaseDatasetRawStats;

  export type StatSegment = BaseStatSegment;

  export type Stats = BaseStats;

  export type Downloadable = BaseDownloadable;

  export type Gap = BaseGap;
