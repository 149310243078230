import { inject } from 'mobx-react';
import {
    DistributionTable as DapDistributionTable,
    LEGACY_ORDER_MAX_FILES,
    OrderMode
} from '@dapclient/components/dataset/DistributionTable';
import { IDatasetService } from '@extensions/services/IDatasetService';
export class DistributionTable extends DapDistributionTable{

    handleFullFileList(largeOrdersOn: boolean | undefined, fileCount: number, datasetService: IDatasetService) {
        if (largeOrdersOn && (fileCount > LEGACY_ORDER_MAX_FILES)) {
            this.setState({ orderMode: OrderMode.LARGE });
        } else {
            this.setState({ orderMode: OrderMode.SMALL });
            if (datasetService.dataset?.getDownloadDistribution()) {
                datasetService.loadDatasetFilesIfNeeded();
            }
        }
    }
}

export default inject((store: any) => ({
    datasetService: store.datasetService,
    securityService: store.securityService,
    notificationService: store.notificationService,
    cartService: store.cartService,
}))(DistributionTable);
