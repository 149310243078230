import { Typography } from "@mui/material";

import theme from '@extensions/services/Theme';

import { IStepProps, Step } from "@extensions/components/data/uploaders/Step";
import Configure from "@extensions/components/data/uploaders/Configure";

const StepConfigure = ({ ...stepProps }: IStepProps) => {
  return (
    <Step
      {...stepProps}
      title="Configure Datasets"
      body={
        <>
          <Typography sx={{color: theme.palette.text.primary}}>
            Configure each dataset to which you will upload files.
          </Typography>
          <Configure />
        </>
      }
    />
  )
};

export default StepConfigure;
