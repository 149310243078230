import React from 'react';
import { inject } from 'mobx-react';
import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import {
  Acknowledge as DapAcknowledge,
} from '@dapclient/components/page/Acknowledge';

import Link from '@extensions/components/core/Link';

const SiteDOITypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[700],
  fontFamily: theme.typography.fontFamily,
  lineHeight: 1.43,
}));
export class Acknowledge extends DapAcknowledge {

  renderSiteDOI = () => {
    return (
      this.siteDoi &&
      <SiteDOITypography variant="body1">
        Site DOI: <Link to={`https://www.osti.gov/search/semantic:${this.siteDoi}`}>{this.siteDoi}</Link>
      </SiteDOITypography>
    )
  }

  renderTextArea = () => {
    return (
      <>
        <Typography variant="h1" gutterBottom>
          Acknowledging Livewire
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          To acknowledge general use of Livewire, select:
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          Click to copy to clipboard:
        </Typography>
        <textarea
          style={{
            fontFamily: "'Open Sans',sans-serif",
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '22px',
            color: 'rgba(0, 0, 0, 0.65)'
          }}
          ref={(textarea) => this.textarea = textarea}
          readOnly
          onClick={this.handleClick}
          value="Data were obtained from Livewire Data Platform funded
                by U.S. Department of Energy, Office of Energy Efficiency and
                Renewable Energy's Vehicle Technologies Office operated and
                maintained by Pacific Northwest National Laboratory, Idaho
                National Laboratory and National Renewable Energy at
                https://livewire.energy.gov." />
        <hr></hr>
        <Typography
          variant="body1"
          sx={{
            color: 'rgba(0, 0, 0, 0.65)'
          }}
        >
          For dataset-specific citations, please refer to individual past data orders or specific dataset web pages.
        </Typography>
      </>
    )
  }

}

export default inject((store: any) => ({
  cachingService: store.cachingService,
  notificationService: store.notificationService,
}))(Acknowledge);
