import {
    default as CoreDistributionType,
    typeToLabel as _typeToLabel,
} from '@dapclient/models/DistributionType';

const LivewireDistributionType = {
    DownloadDap: 'download-livewire',
} as const;

const DistributionType = {
    ...CoreDistributionType,
    ...LivewireDistributionType,
};

export default DistributionType;

export const typeToLabel = _typeToLabel;
