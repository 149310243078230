import React from 'react';
import { inject } from 'mobx-react';

import {
  Footer as DapFooter,
} from '@dapclient/components/nav/Footer';

export class Footer extends DapFooter {
  getLinks = () => [
    {
      children: 'Contact Us',
      onClick: () =>
        this.props.contactUsService &&
        this.props.contactUsService.openModal(),
    },
    {
      children: 'Acknowledge Livewire',
      href: '/acknowledge',
    },
    {
      children: 'Vehicle Technologies Office',
      href: 'https://www.energy.gov/eere/vehicles/vehicle-technologies-office',
    },
    {
      children: <>Office of Energy Efficiency &amp; Renewable Energy</>,
      href: 'https://www.energy.gov/eere/office-energy-efficiency-renewable-energy',
    },
    {
      children: 'Web Policies',
      href: 'https://www.energy.gov/about-us/web-policies',
    },
    {
      children: 'Privacy',
      href: 'https://www.energy.gov/about-us/web-policies/privacy',
    },
  ];
}

export default inject((store: any) => ({
  contactUsService: store.contactUsService,
}))(Footer);
