import React from 'react';
import { inject, observer } from 'mobx-react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router-dom';

import doeEereLogo from '@extensions/resources/doe-eere-logo.png';
import liveWireLogo from '@extensions/resources/Livewire Cutout.png';
import sponsorAppLogo from '@extensions/resources/sponsorAppLogo.png';

import { ISecurityService } from '@extensions/services/ISecurityService';
import { INotificationService } from '@extensions/services/INotificationService';

import User from '@extensions/models/User';
import ResponsiveLogo from './ResponsiveLogo';
import ResponsiveMenu from '@extensions/components/nav/ResponsiveMenu';
import UserMenuButton from '@extensions/components/user/UserMenuButton';

import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { AppBar, Box, Tabs, Tab, Link, CircularProgress } from '@mui/material';
import HighlightOutlinedIcon from '@mui/icons-material/HighlightOutlined';

import theme from '@extensions/services/Theme';
import SignInLinkMobile from '@dapclient/components/core/SignInLinkMobile';

const StyledAppBar = styled(AppBar)(({
  marginTop: 15,
  padding: '0px 0px 0px 0px',
  height: 45,
  flexDirection: 'row',
}));

const StyledSponsorLogo = styled('img')(({
  float: 'left',
  marginTop: '-15px',
  width: '155px',
}));

const StyledAppLogo = styled('img')(({
  float: 'left',
  paddingTop: '8px',
  paddingLeft: '10px',
  height: '32px',
}));

const StyledNavDiv = styled('div')(({
  marginLeft: 'auto',
}));

const StyledTabs = styled(Tabs)(({
  minHeight: 'unset',
  '& .MuiTab-root': {
    color: '#fff',
    height: 45,
    textTransform: 'none',
    background: theme.palette.primary.main,
    minWidth: 'auto',
    padding: '0px 16px',
    opacity: 'unset',
    minHeight: 'unset',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
    },
    '&.Mui-selected': {
      color: '#fff',
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledTabsMobile = styled(Tabs)(({
  minHeight: 'unset',
  '& .MuiTab-root': {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  },
}));

const StyledLink = styled(Link)(({
  color: '#fff',
  textTransform: 'none',
  minWidth: 'auto',
  padding: '14px 16px',
  opacity: 'unset',
  minHeight: 'unset',
  textDecoration: 'none',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
  },
  [theme.breakpoints.down('md')]: {
    minHeight: 'unset',
    color: 'rgba(0, 0, 0, 0.65)',
    textTransform: 'uppercase',
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
    },
    '&.Mui-selected': {
      background: theme.palette.secondary.main,
    },
  }
}));

const StyledCircularProgress = styled(CircularProgress)(({
  '&.MuiCircularProgress-root': {
    width: '20px !important',
    height: '20px !important',
  },
  '& svg': {
    color: 'white',
  },
}));

const StyledIconButton = styled(IconButton)(({
  color: 'white',
  borderRadius: 'unset',
  padding: 8,
  '&:hover': {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    textDecoration: 'none',
  },
  '& > span svg': {
    fontSize: '18px',
  },
  '& .MuiButton-label': {
    minWidth: '0px !important',
  },
  '& > svg path': {
    color: 'white'
  }
}));

const StyledBox = styled(Box)(({
  width: '100%',
  display: 'flex',
  backgroundColor: theme.palette.primary.main,
}));

const NavTab = (props: NavTabProps) => {
  const location = useLocation()
  const isActive = location.pathname === props.value;
  return (
    <StyledLink
      sx={{   
        backgroundColor: isActive ? theme.palette.secondary.main : theme.palette.primary.main,
        [theme.breakpoints.down('md')]: {
          backgroundColor: isActive ? theme.palette.secondary.main : 'white',
        },
      }}
      href={props.value}
    >
      {props.label}
    </StyledLink>
  )
};
interface NavTabProps {
  label: string;
  value: string;
  to?: any;
}
export interface IHeaderProps extends RouteComponentProps {
  securityService?: ISecurityService;
  notificationService?: INotificationService;
}

export interface IHeaderState { }

@observer
export class Header extends React.Component<
  IHeaderProps,
  IHeaderState
> {
  onToggleShowNotifications = () => {
    if (this.props.notificationService) {
      this.props.notificationService.setShowNotifications(
        !this.props.notificationService.showNotifications
      );
    }
  };

  renderNotificationButton = (): React.ReactNode => {
    let notificationsIcon = <HighlightOutlinedIcon fontSize='small'/>;
    if (this.props.notificationService?.error) {
      notificationsIcon = <ErrorOutlineIcon />;
    } else if (this.props.notificationService?.downloading) {
      notificationsIcon = (
        <StyledCircularProgress />
      );
    }

    const notificationButton = (
      <StyledIconButton
        onClick={this.onToggleShowNotifications}
      >
        {notificationsIcon}
      </StyledIconButton>
    );

    return notificationButton;
  };

  getDesktopLogo = (): React.ReactNode => (
    <>
      <StyledSponsorLogo src={doeEereLogo} alt="logo" />
      <StyledAppLogo src={liveWireLogo} alt="LiveWire Data Platform Logo" />
    </>
  );

  getMobileLogo = (): React.ReactNode => (
    <StyledSponsorLogo src={sponsorAppLogo} alt="LiveWire Data Platform Logo" />
  );

  getPublicMenuItems = (): NavTabProps[] => [
    {
      value: '/',
      label: 'Home',
      to: {
        pathname: '/',
        state: { logout: false },
      },
    },
    {
      value: '/about',
      label: 'About',
    },
    {
      value: '/faq',
      label: 'FAQ',
    },
    {
      value: '/metrics',
      label: 'Metrics',
    },
    {
      value: '/project-search',
      label: 'Projects',
    },
    {
      value: '/dataset-search',
      label: 'Datasets',
    },
  ];

  getProtectedMenuItems = (): NavTabProps[] => [];

  render() {
    const { securityService, notificationService } = this.props;
    if (!notificationService || !securityService) {
      return null;
    }
    const user: User | null = securityService.user;

    const innerMenu: React.ReactNode[] = this.getPublicMenuItems().map(
      (props) => <NavTab key={props.label} {...props} />
    );

    if (user && user.authenticated) {
      innerMenu.push(
        this.getProtectedMenuItems().map((props) => (
          <NavTab key={props.label} {...props} />
        ))
      );
      innerMenu.push(
        <Tab
          sx={{ color: theme.palette.text.primary }}
          key="user"
          value="/profile"
          icon={
            <UserMenuButton
              isRenderedWithinButton={true}
            />
          }
        />
      );
    } else {
      innerMenu.push(
        <SignInLinkMobile key={'signin'} label="Sign In" />
      );
    }

    const value = this.props.location.pathname.split('/').slice(0, 2).join('/');
    const matches =
      React.Children.toArray(innerMenu).filter((t: any) => {
        return t.props && t.props.value === value;
      }).length > 0;

    // user profile page has inner nav to /profile or /profile/orders so let either pathname show the user button as selected
    const webMenu = (
      <>
        <StyledTabs key={this.props.location.key} value={matches ? value : false}>
          {innerMenu}
        </StyledTabs>
      </>
    );

    //mobileMenu doesn't have any of the styling to make the tabs look like they're a part of the black app bar, it uses MUI default styling
    const mobileMenu = (
      <>
        <StyledTabsMobile orientation="vertical" value={this.props.location.pathname}>
          {innerMenu}
        </StyledTabsMobile>
      </>
    );

    return (
      <StyledAppBar position="static">
        <StyledBox>
          <ResponsiveLogo desktop={this.getDesktopLogo()} mobile={this.getMobileLogo()} />
          <StyledNavDiv>
            <ResponsiveMenu desktop={webMenu} mobile={mobileMenu} />
          </StyledNavDiv>
          {this.renderNotificationButton()}
        </StyledBox>
      </StyledAppBar>
    );
  }
}

export default inject((store: any) => ({
  securityService: store.securityService,
  notificationService: store.notificationService,
}))(withRouter(Header));
